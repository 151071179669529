import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BackgroundSection from "../components/Globals/BackgroundSection"
import ContactForm from "../components/Home/ContactForm"
import Img from "../images/homeGallery/qd-landing-page-dpi.png"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Love" />
    <BackgroundSection
      img={data.img.childImageSharp.fluid}
      // title="retro mood"
      styleClass="default-background"
    />

    <ContactForm />
  </Layout>
)

export const query = graphql`
  {
    img: file(relativePath: { eq: "homeGallery/qd-landing-page-dpi.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

export default IndexPage
